import React, { useEffect, useState } from "react";

export const LanguageContext = React.createContext("nl");

export const Language = ({children}) => {
    var defaultlang = 'nl';
    if (typeof window !== 'undefined') {
        defaultlang = localStorage.getItem("language")||'nl';
    } 
    const [lang, setLang] = useState(defaultlang)

    let val = {
        lang: lang, 
        setLang: setLang
    };
    
    useEffect(() => {
        if (typeof window !== 'undefined') {
            localStorage.setItem("language", lang)
        }
    }, [lang])

    return (
        <LanguageContext.Provider value={val}>
            {children}
        </LanguageContext.Provider>
    )
}
