exports.components = {
  "component---src-components-layout-posts-jsx": () => import("./../../../src/components/layoutPosts.jsx" /* webpackChunkName: "component---src-components-layout-posts-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-association-js": () => import("./../../../src/pages/association.js" /* webpackChunkName: "component---src-pages-association-js" */),
  "component---src-pages-companies-js": () => import("./../../../src/pages/companies.js" /* webpackChunkName: "component---src-pages-companies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-students-js": () => import("./../../../src/pages/students.js" /* webpackChunkName: "component---src-pages-students-js" */)
}

